import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './BandSideComponent.css'; // Import the CSS file for styling

const BandSideComponent = ({ setView }) => {
    const [activeItem, setActiveItem] = useState('proposalList'); // Default active item

    const handleNavItemClick = (item) => {
        setActiveItem(item);
        setView(item); // Assuming setView will control which component to render based on the item clicked
    };

    //setView('proposalList'); 

    return (
        <div className="side-navbar fixed-side-bar"> {/* Updated class names */}
            <Navbar bg="white" expand="lg" className="side-navbar">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="flex-column">
                        <Nav.Link 
                            onClick={() => handleNavItemClick('proposalList')} 
                            active={activeItem === 'proposalList'}
                        >
                            Proposals Sent
                        </Nav.Link>

                        <Nav.Link 
                            onClick={() => handleNavItemClick('organizerproposalList')} 
                            active={activeItem === 'organizerproposalList'}
                        >
                            Proposals From Organizer
                        </Nav.Link>

                        <Nav.Link 
                            onClick={() => handleNavItemClick('mygigs')} 
                            active={activeItem === 'mygigs'}
                        >
                            My Gigs
                        </Nav.Link>

                        <Nav.Link 
                            onClick={() => handleNavItemClick('AccountDetails')} 
                            active={activeItem === 'AccountDetails'}
                        >
                            Account Details
                        </Nav.Link>
                        {/* Add more Nav.Link elements as needed */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default BandSideComponent;

