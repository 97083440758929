import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

// Helper function to convert military time to standard time
const convertToStandardTime = (time) => {
  let [hour, minute] = time.split(':');
  hour = parseInt(hour, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;
  return `${hour}:${minute} ${ampm}`;
};

const OrganizerBookedGigs = ({ organizerId }) => {
  const [gigs, setGigs] = useState([]);
  const [selectedGig, setSelectedGig] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Fetch the gigs by Organizer_id
    fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetBookedGigsByOrganizerID.php?Organizer_id=${organizerId}`)
      .then((response) => response.json())
      .then((data) => setGigs(data))
      .catch((error) => console.error('Error fetching gigs:', error));
  }, [organizerId]);

  const handleShowModal = (gig) => {
    setSelectedGig(gig);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedGig(null);
  };

  return (
    <div>
       {gigs.length > 0 ? (
        gigs.map((gig) => (
        <div
          key={gig.Booking_id}
          style={{
            borderRadius: '10px',
            border: '2px solid #ccc',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            padding: '15px',
            marginBottom: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>
            <h4 style={{ fontWeight: 'bold' }}>{gig.Gig_name}</h4>
            <p>
              {gig.Gig_startdate} - {gig.Gig_enddate} <br />
              {convertToStandardTime(gig.Gig_starttime)} -{' '}
              {convertToStandardTime(gig.Gig_endtime)}
            </p>
          </div>
          <Button
            variant="info"
            onClick={() => handleShowModal(gig)}
            style={{ marginLeft: '10px' }}
          >
            Info
          </Button>
        </div>
      ))

    ) : (
        <p>No Booked Gigs</p>
      )}

      {/* Modal for gig details */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {selectedGig && selectedGig.Gig_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedGig && (
            <div>
              <p>
                <strong>Start Date:</strong> {selectedGig.Gig_startdate}
              </p>
              <p>
                <strong>End Date:</strong> {selectedGig.Gig_enddate}
              </p>
              <p>
                <strong>Start Time:</strong>{' '}
                {convertToStandardTime(selectedGig.Gig_starttime)}
              </p>
              <p>
                <strong>End Time:</strong>{' '}
                {convertToStandardTime(selectedGig.Gig_endtime)}
              </p>
              <p>
                <strong>Booked Price:</strong> ${selectedGig.Booked_price}
              </p>

              {/* Google Map */}
              <div style={{ width: '100%', height: '300px' }}>
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyAF2Q8fA6MwI4ggg-uxaSnOk4tCeCZH7Ds&q=${encodeURIComponent(
                    selectedGig.Gig_address
                  )}`}
                  allowFullScreen
                  title="Gig Location"
                ></iframe>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default OrganizerBookedGigs;
