import React, { useState, useEffect } from 'react';
import { Button, Modal, Dropdown, Form, Spinner } from 'react-bootstrap';
import { FaInfoCircle, FaEdit, FaUser, FaReply } from 'react-icons/fa';
import './GigProposalList.css';

//THIS IS FOR THE ORGANIZER

const OrganizerProposalListSent = ({ userID }) => {
    const [proposals, setProposals] = useState([]);
    const [selectedProposal, setSelectedProposal] = useState(null);
    const [selectedRegistrationStatus, setSelectedRegistrationStatus] = useState('');
    const [activeModal, setActiveModal] = useState(null); // Use a single state to manage modals
    const [bandProfile, setBandProfile] = useState(null);
    const [proposalPrice, setProposalPrice] = useState('');
    const [proposalMessage, setProposalMessage] = useState('');
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isLoadingProfile, setIsLoadingProfile] = useState(false); 
    const [venueId, setVenueId] = useState(null);

    useEffect(() => {
        fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/BandAccountGetOrganizerProposals.php?Organizer_id=${userID}`)
            .then(response => response.json())
            .then(data => setProposals(data.data))
            .catch(error => console.error('Error fetching proposals:', error));
    }, [userID]);

    useEffect(() => {
        if (selectedProposal) {
            fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetGigInfo.php?Gig_id=${selectedProposal.Gig_id}`)
                .then(response => response.json())
                .then(data => {
                    setVenueId(data.VenueID);
                })
                .catch(error => console.error('Error fetching gig info:', error));
        }
    }, [selectedProposal]);

    const handleStatusChange = (status) => {
        setSelectedRegistrationStatus(status);
    };

    const handleSaveChanges = () => {
        const selectedProposalID = selectedProposal.Band_Proposal_id;

        if (selectedRegistrationStatus === "Accepted") {
            fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/BookTheGig.php?Band_Proposal_id=${selectedProposalID}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ Band_Proposal_id: selectedProposalID })
            })
            .then(response => response.json())
            .then(() => {
                alert("Gig has been booked successfully!");
            })
            .catch(error => console.error('Error booking the gig:', error));
        }

        fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/SetProposalStatusSentByOrganizer.php?Organizer_Proposal_id=${selectedProposalID}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                Organizer_Proposal_id: selectedProposalID,
                Proposal_Status: selectedRegistrationStatus,
            })
        })
        .then(response => response.json())
        .then(() => {
            alert("Proposal status updated successfully");
            setProposals(proposals.map(p => p.Band_Proposal_id === selectedProposalID ? { ...p, Proposal_Status: selectedRegistrationStatus } : p));
            setSelectedProposal(null);
        })
        .catch(error => console.error('Error updating proposal status:', error));

        if (selectedRegistrationStatus === "Accepted") {
            const subject = "Your Proposal Has Been Accepted";
            const body = "Congratulations! Your proposal has been accepted.";
            window.location.href = `mailto:${selectedProposal.Band_Email}?subject=${subject}&body=${body}`;
        }
    };

    const handleDeleteProposal = () => {
        const selectedProposalID = selectedProposal.Band_Proposal_id;
        fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/DeleteProposalSentByOrganizerByID.php?Organizer_Proposal_id=${selectedProposalID}`)
            .then(response => response.json())
            .then(() => {
                alert("Proposal deleted successfully");
                setProposals(proposals.filter(p => p.Band_Proposal_id !== selectedProposalID));
                setSelectedProposal(null);
            })
            .catch(error => console.error('Error deleting proposal:', error));
    };

    const fetchBandProfile = (bandId) => {
        setIsLoadingProfile(true); // Start loading
        fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetBandInformationByID.php?Band_ID=${bandId}`)
            .then(response => response.json())
            .then(data => {
                console.log("Response: " + JSON.stringify(data.bandData));
                setBandProfile(data.bandData);
                setIsLoadingProfile(false); // Stop loading
                setActiveModal('profile'); // Open the profile modal
            })
            .catch(error => {
                console.error('Error fetching band profile:', error);
                setIsLoadingProfile(false); // Stop loading on error
            });
    
    };

    

   
    
    

    const getStatusLabel = (status) => {
        switch (status) {
            case 'Accepted':
                return 'Accepted';
            case 'Proposal':
                return 'Proposal';
            case 'Canceled':
                return 'Canceled';
            case 'Archived':
                return 'Archived';
            default:
                return 'Submitted';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 'Accepted':
                return 'green';
            case 'Proposal':
                return 'blue';
            case 'Canceled':
                return 'red';
            case 'Archived':
                return 'gray';
            default:
                return 'orange';
        }
    };

    return (
        <div className="gig-proposal-list">
            {proposals.map(proposal => (
                <div key={proposal.Band_Proposal_id} className="proposal-card">
                    <div className="proposal-info">
                        <div className="proposal-title">{`${proposal.Gig_Title}`}</div>
                        <div className="proposal-title">{`${proposal.Venue}`}</div>
                        <div className="proposal-email">{proposal.Organizer_email}</div>
                       
                        <div className="proposal-event">Date Registered: {proposal.Date_Registered}</div>
                    </div>
                    <div className="proposal-footer">
                        <div className="proposal-status" style={{ backgroundColor: getStatusColor(proposal.Proposal_Status) }}>
                            {getStatusLabel(proposal.Proposal_Status)}
                        </div>
                        <Button
                            variant="info"
                            className="details-button"
                            onClick={() => {
                                setSelectedProposal(proposal);
                                setActiveModal('details'); // Open the details modal
                            }}
                        >
                            <FaInfoCircle />
                        </Button>
                       
                        
                        <div style={{ padding:'10px' }}>
                        <Button variant="danger" onClick={()=>{
                            setSelectedProposal(proposal);
                            handleDeleteProposal();                             
                        }}>Unsend</Button>
                        </div>
                    </div>
                </div>
            ))}

            {/* Details Modal */}
            <Modal show={activeModal === 'details' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Proposal Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Price:</strong> {selectedProposal?.Proposed_Price}</p>
                    <p style={{ paddingTop: '10px' }}><strong>Message:</strong> {selectedProposal?.Proposed_Message}</p>
                    <p><strong>Organizer Email:</strong> {selectedProposal?.Organizer_email}</p>
                    <p><strong>Gig:</strong> {selectedProposal?.Gig_Title}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Edit Modal */}
            <Modal show={activeModal === 'edit' && selectedProposal !== null} onHide={() => setActiveModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Proposal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Registration Status</strong></p>
                    <Dropdown onSelect={handleStatusChange} disabled={selectedProposal?.Proposal_Status === 'Accepted'}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            {selectedRegistrationStatus || getStatusLabel(selectedProposal?.Proposal_Status)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="Accepted">Accepted</Dropdown.Item>
                            <Dropdown.Item eventKey="Proposal">Proposal</Dropdown.Item>
                            <Dropdown.Item eventKey="Canceled">Canceled</Dropdown.Item>
                            <Dropdown.Item eventKey="Archived">Archived</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setActiveModal(null)}>Close</Button>
                    <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
                    <Button variant="danger" onClick={handleDeleteProposal}>Delete</Button>
                </Modal.Footer>
            </Modal>

            
            
        </div>
    );
};

export default OrganizerProposalListSent;
