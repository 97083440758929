import React, { createContext, useContext, useEffect, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(null);

  useEffect(() => {
    const loadAuthData = () => {
      try {
        const storedAuthData = localStorage.getItem('authData');
        if (storedAuthData) {
          setAuthData(JSON.parse(storedAuthData));
        }
      } catch (error) {
        console.error('Error loading authentication data:', error.message || 'Unknown error');
      }
    };

    loadAuthData();
  }, []);


  const blankAuthData = {
    user_id: 0,
    user_login: '',
    auth_token: '',
    roles: '',
    logout: () => {
      // Implement logout logic if needed
      console.log('Logging out...');
    }
  };
  const setAuthenticationData = (data) => {
    setAuthData(data);
    try {
      localStorage.setItem('authData', JSON.stringify(data));
    } catch (error) {
      console.error('Error saving authentication data:', error.message || 'Unknown error');
    }
  };

  const logout = () => {
    setAuthData(null);
    try {
      localStorage.removeItem('authData');
    } catch (error) {
      console.error('Error clearing authentication data:', error.message || 'Unknown error');
    }
  };

  return (
    <AuthContext.Provider value={{ authData, setAuthenticationData, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};


const testAuthData = {
  user_id: 93,
  user_login: 'Jim Bob',
  auth_token: 'your-test-auth-token',
  roles: 'organizer',
  logout: () => {
    // Implement logout logic if needed
    console.log('Logging out...');
  }
};

export default testAuthData; // for the aws test for now since there is no login




