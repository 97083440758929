import React, { useState, useEffect } from 'react';

const VenueCount = ({ userID }) => {
  const [totalVenues, setTotalVenues] = useState(0);
  const [limitVenues] = useState(5);

  useEffect(() => {
    const fetchTotalVenues = async () => {
      try {
        const response = await fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetTotalNumberOfVenues.php?organizerID=${userID}`);
        const data = await response.json();

        if (response.ok) {
          setTotalVenues(data.totalVenues || 0);
        } else {
          console.error('Failed to fetch total venues:', data.error || 'Unknown error');
        }
      } catch (error) {
        console.error('Error fetching total venues:', error.message || 'Unknown error');
      }
    };

    fetchTotalVenues();
  }, [userID]);

  return (
    <div>
      <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
        {totalVenues} Venues Out Of {limitVenues} Created
      </p>
    </div>
  );
};

export default VenueCount;
