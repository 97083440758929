import React from 'react';
import { useAuth } from '../Components/AuthContext';
import OrganizerEventList from '../Components/OrganizerEventList';
import OrganizerSideComponent from '../Components/OrganizerSideComponent';
import GigProposalList from '../Components/GigProposalList';
import BandListComponent from '../Components/BandListComponent'; // Import BandListComponent
import VenueListComponent from '../Components/VenueListComponent'; // Import VenueListComponent
import GigCreatorComponent from '../Components/GigCreatorComponent'; // Import GigCreatorComponent
import VenueCreatorComponent from '../Components/VenueCreatorComponent';
import './AccountScreen.css';
import BandSideComponent from '../Components/BandSideComponent';
import BandAccountProposalList from '../Components/BandAccountProposalList';
import OrganizerAccountDetails from '../Components/OrganizerAccountDetails';
import BandAccountDetails from '../Components/BandAccountDetails';
import OrganizerProposalListSent from '../Components/OrganizerProposalListSent';
import BandAccountGigOrganizerProposalList from '../Components/BandAccountGigOrganizerProposalList';
import OrganizerBookedGigs from '../Components/OrganizerBookedGigs';
import BandBookedGigs from '../Components/BandBookedGigs';


const AccountScreen = () => {
    const { authData } = useAuth();
    const [view, setView] = React.useState('gigs'); // Initial view

    if (!authData) {
        return null;
    }
    
//console.log("USERID ACCOUNTSCREEN: "+authData.user_id);
    return (
        <div className="account-screen-container">
            <div className="sidebar">
            {authData.role === 'organizer' && (
                <OrganizerSideComponent setView={setView} /> 
            )}
            {authData.role === 'subscriber' && (
                <BandSideComponent setView={setView} /> 
            )}

            </div>
            <div className="main-content">
                {view === 'gigs' && authData.role === 'organizer' && (
                    <OrganizerEventList UserIDnumber={authData.user_id} token={authData.token} />
                )}
                {view === 'proposals' && authData.role === 'organizer' && (
                    <GigProposalList userID={authData.user_id} /> 
                )}
                {view === 'proposals_sent' && authData.role === 'organizer' && (
                    <OrganizerProposalListSent userID={authData.user_id} /> 
                )}
                {view === 'bandlist' && authData.role === 'organizer' && (
                    <BandListComponent Organizer_ID ={authData.user_id}/>
                )}
                {view === 'venues' && authData.role === 'organizer' && (
                    <VenueListComponent userID={authData.user_id} /> // Render VenueListComponent
                )}
                {view === 'createGig' && authData.role === 'organizer' && (
                    <GigCreatorComponent UserIDnumber={authData.user_id} /> // Render GigCreatorComponent
                )}
                
                {view === 'createVenue' && authData.role === 'organizer' && (
                   
                    <VenueCreatorComponent UserIDnumber={authData.user_id} /> // Render GigCreatorComponent
                )}
                 {view === 'proposalList' && authData.role === 'subscriber' && (
                   
                   <BandAccountProposalList userid={authData.user_id} /> // Render GigCreatorComponent
               )}
                {view === 'organizerproposalList' && authData.role === 'subscriber' && (
                   
                   <BandAccountGigOrganizerProposalList userID={authData.user_id} /> // Render GigCreatorComponent
               )}
               {view === 'bookedgigs' && authData.role === 'organizer' && (
                   
                   <OrganizerBookedGigs organizerId={authData.user_id} /> // Render GigCreatorComponent
               )}
               {view === 'mygigs' && authData.role === 'subscriber' && (
                   
                   <BandBookedGigs bandId={authData.user_id} /> // Render GigCreatorComponent
               )}
               {view === 'AccountDetails' && authData.role === 'organizer' && (
                   
                   <OrganizerAccountDetails userId={authData.user_id} /> // Render GigCreatorComponent
               )}
                {view === 'AccountDetails' && authData.role === 'subscriber' && (
                   
                   <BandAccountDetails userid={authData.user_id} /> // Render GigCreatorComponent
               )}
            </div>
        </div>
    );
};

export default AccountScreen;


