import React from 'react';
import ReactPlayer from 'react-player';
import './VideoBackgroundComponent.css';

const VideoBackgroundComponent = () => {
  return (
    <div className="video-background">
      <ReactPlayer
        url="https://www.youtube.com/watch?v=FpkX2VdXZJs" // Replace with your video URL
        playing
        loop
        muted
        width="100%"
        height="100%"
        className="video-iframe"
        controls={false} // Disable video controls
      />
      <div className="video-overlay">
        <h1>My Gig Slots</h1>
        <p>Helping musicians find gigs and gigs find musicians</p>
      </div>
    </div>
  );
};

export default VideoBackgroundComponent;
