import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';

// Utility function to format date and time
const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', {
    dateStyle: 'short',
    timeStyle: 'short',
    hour12: true,
  });
};



const GetSubscriptionInfo = ({ stripeCustomerID,role }) => {
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Utility function to determine subscription type
const determineSubscriptionType = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
  
    // Calculate the difference in months
    const diffInMonths = (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
  
    if (diffInMonths === 1) {
      if(role=='organizer')
      {
      return '1 Month Subscription for $9.99';
      }
      if(role=='subscriber')
          {
              return '1 Month Subscription for $0.99';
          }
          return'';
    } else if (diffInMonths === 12) {
      if(role=='organizer')
          {
          return '1 Year Subscription for $100';
          }
          if(role=='subscriber')
              {
                  return '1 Year Subscription for $9.99';
              }
              return'';
    } else {
      return 'Other Subscription';
    }
  };

  useEffect(() => {
    const fetchSubscriptionInfo = async () => {
      if (!stripeCustomerID) {
        // Do not fetch if stripeCustomerID is not available
        return;
      }

      setLoading(true); // Show spinner while loading
      setError(null);   // Reset error state

      try {
        // Send POST request to the PHP endpoint with the customer_id
        const response = await axios.post(
          'http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetStripeSubscriptionInfo.php',
          new URLSearchParams({ customer_id: stripeCustomerID }), // URL-encoded payload
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded', // Set the correct content type
            },
          }
        );

        // Handle the response
        if (response.data.status === 'success') {
          console.log('Subscription info: ' + JSON.stringify(response.data.subscriptions));
          const subscriptionsWithType = response.data.subscriptions.map(subscription => ({
            ...subscription,
            subscriptiontype: determineSubscriptionType(subscription.current_period_start, subscription.current_period_end),
          }));
          setSubscriptionInfo(subscriptionsWithType);
        } else {
          setError(response.data.message || 'Error retrieving subscription information');
        }
      } catch (err) {
        console.error('Fetch error:', err); // Log the error for debugging
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false); // Stop spinner after the request completes
      }
    };

    fetchSubscriptionInfo();
  }, [stripeCustomerID]); // Dependency array ensures effect runs when stripeCustomerID changes

  return (
    <div>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : subscriptionInfo && subscriptionInfo.length > 0 ? (
        <div>
          <h3>Subscription Information</h3>
          <ul>
            {subscriptionInfo.map((subscription, index) => (
              <li key={index}>
                <p><strong>Status:</strong> {subscription.status}</p>
                <p><strong>Subscription:</strong> {subscription.subscriptiontype}</p>
                <p><strong>Period Start:</strong> {formatDateTime(subscription.current_period_start)}</p>
                <p><strong>Period End:</strong> {formatDateTime(subscription.current_period_end)}</p>
                <p><strong>Next Billing Date:</strong> {formatDateTime(subscription.next_billing_date)}</p>
                <p><strong>Cancel at Period End:</strong> {subscription.cancel_at_period_end ? 'Yes' : 'No'}</p>
                {subscription.canceled_at && (
                  <p><strong>Canceled At:</strong> {formatDateTime(subscription.canceled_at)}</p>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>No subscription information available.</p>
      )}

      {error && <p>Error: {error}</p>}
    </div>
  );
};

export default GetSubscriptionInfo;

