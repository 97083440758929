import React from 'react';
import CalendarComponent from '../Components/CalendarComponent';
import { useAuth } from '../Components/AuthContext';

const CalendarScreen = () => {
    const { authData, logout } = useAuth();
  return (
    <div>

      <CalendarComponent Role={authData?.role} AuthData={authData} />
    </div>
  );
};

export default CalendarScreen;
