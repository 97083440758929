import React, { useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import './OrganizerSideComponent.css'; // Import the CSS file for styling

const OrganizerSideComponent = ({ setView }) => {
    const [activeItem, setActiveItem] = useState('gigs'); // Default active item
    const [showCreatorOptions, setShowCreatorOptions] = useState(false);

    const handleNavItemClick = (item) => {
        setActiveItem(item);
        setView(item); // Assuming setView will control which component to render based on the item clicked
    };

    const handleCreatorClick = () => {
        setShowCreatorOptions(!showCreatorOptions);
        setActiveItem('creator');
    };

    return (
        <div className="side-navbar fixed-side-bar"> 
            <Navbar bg="white" expand="lg" className="side-navbar">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="flex-column">
                        <Nav.Link onClick={handleCreatorClick} active={activeItem === 'creator'}>
                            Creator
                        </Nav.Link>
                        {showCreatorOptions && (
                            <>
                                <Nav.Link onClick={() => handleNavItemClick('createGig')} active={activeItem === 'createGig'}>
                                    Create Gig
                                </Nav.Link>
                                <Nav.Link onClick={() => handleNavItemClick('createVenue')} active={activeItem === 'createVenue'}>
                                    Create Venue
                                </Nav.Link>
                            </>
                        )}
                        <Nav.Link onClick={() => handleNavItemClick('gigs')} active={activeItem === 'gigs'}>
                            Gigs
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavItemClick('venues')} active={activeItem === 'venues'}>
                            Venues
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavItemClick('bandlist')} active={activeItem === 'bandlist'}>
                            Band List
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavItemClick('proposals')} active={activeItem === 'proposals'}>
                            Proposals From Bands
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavItemClick('proposals_sent')} active={activeItem === 'proposals_sent'}>
                            Proposals Sent
                        </Nav.Link>
                        <Nav.Link onClick={() => handleNavItemClick('bookedgigs')} active={activeItem === 'bookedgigs'}>
                            Booked Gigs
                        </Nav.Link>

                        <Nav.Link 
                            onClick={() => handleNavItemClick('AccountDetails')} 
                            active={activeItem === 'AccountDetails'}
                        >
                            Account Details
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default OrganizerSideComponent;


