import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import './VenueListComponent.css'; // Import the CSS file for styling
import VenueDuplicateComponent from './VenueDuplicateComponent';
import VenueEditComponent from './VenueEditComponent';
import VenueCount from './VenueCount';

const VenueListComponent = ({ userID }) => {
    const [venues, setVenues] = useState([]); // Initialize as empty array
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchVenues = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetVenuesByOrganizerID.php?OrganizerID=${userID}`, {
                    method: 'GET',
                    mode: 'cors',
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                console.log('Fetched venues:', data); // Debugging line

                setVenues(Array.isArray(data) ? data : []);
            } catch (error) {
                setError('Error fetching venues. Please try again later.');
                console.error('Error fetching venues:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchVenues();
    }, [userID]);

    const fetchVenuesUpdate = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetVenuesByOrganizerID.php?OrganizerID=${userID}`, {
                method: 'GET',
                mode: 'cors',
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Fetched venues:', data); // Debugging line

            setVenues(Array.isArray(data) ? data : []);
        } catch (error) {
            setError('Error fetching venues. Please try again later.');
            console.error('Error fetching venues:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDetailsClick = async (venue) => {
        try {
            const response = await fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetVenueInformationByID.php?Venue_ID=${venue.Venue_ID}`, {
                method: 'GET',
                mode: 'cors',
            });
            const data = await response.json();
            setSelectedVenue(data);
            setShowModal(true);
            setModalAction('details');
        } catch (error) {
            console.error('Error fetching venue details:', error);
        }
    };

    const handleEditClick = async (venue) => {
        setSelectedVenue(venue);
        setShowModal(true);
        setModalAction('edit');
    };

    const handleDuplicateClick = async (venue) => {
        setSelectedVenue(venue);
        setShowModal(true);
        setModalAction('duplicate');
    };

    const handleDeleteClick = async (venue) => {
        setSelectedVenue(venue);
        setShowModal(true);
        setModalAction('delete');
    };

    const handleDeleteConfirm = async () => {
        try {
            const response = await fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/DeleteVenueByID.php?Venue_ID=${selectedVenue.Venue_ID}`, {
                method: 'GET',
                mode: 'cors',
            });
            if (!response.ok) {
                throw new Error('Failed to delete venue');
            }
            setVenues(venues.filter(v => v.Venue_ID !== selectedVenue.Venue_ID));
            setShowModal(false);
            setSelectedVenue(null);
        } catch (error) {
            console.error('Error deleting venue:', error);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedVenue(null);
        setModalAction(null);
    };

    return (
        <div className="venue-list-container">
           < VenueCount userID={userID}/>
            {loading ? (
                <div className="spinner-container">
                    <Spinner animation="border" />
                    <p>Loading venues...</p>
                </div>
            ) : error ? (
                <div className="error-container">
                    <p>{error}</p>
                </div>
            ) : venues.length > 0 ? (
                venues.map((venue) => (
                    <div key={venue.Venue_ID} className="venue-card">
                        <div className="venue-card-header">
                            <span>Venue Name</span>
                            <span>Action</span>
                        </div>
                        <div className="venue-card-body">
                            <strong>{venue.Venue_name}</strong>
                            <p>{venue.Venue_Description}</p>
                            <p><strong>Gigs: </strong>{venue.totalGigs || 0}</p>
                            <div className="venue-card-actions">
                                <Button variant="secondary" className="action-button" onClick={() => handleEditClick(venue)}>Edit</Button>
                                <Button variant="secondary" className="action-button" onClick={() => handleDuplicateClick(venue)}>Duplicate</Button>
                                <Button variant="danger" className="action-button" onClick={() => handleDeleteClick(venue)}>Delete</Button>
                                <Button variant="info" className="action-button" onClick={() => handleDetailsClick(venue)}>Details</Button>
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <p>No venues found.</p>
            )}

            {selectedVenue && (
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {modalAction === 'details' && 'Venue Details'}
                            {modalAction === 'edit' && 'Edit Venue'}
                            {modalAction === 'duplicate' && 'Duplicate Venue'}
                            {modalAction === 'delete' && 'Delete Venue'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalAction === 'details' && (
                            <>
                                <p><strong>Name:</strong> {selectedVenue.Venue_name}</p>
                                <p><strong>Description:</strong> {selectedVenue.Venue_Description}</p>
                                <p><strong>Location:</strong> {selectedVenue.Venue_address || 'N/A'}</p>
                                <p><strong>Zipcode:</strong> {selectedVenue.Venue_zipcode || 'N/A'}</p>
                            </>
                        )}
                        {modalAction === 'edit' && (
                            <VenueEditComponent
                                selectedVenue={selectedVenue}
                                UserIDnumber={userID}
                                onHide={handleCloseModal}
                                onUpdate={fetchVenuesUpdate} // Pass reference, not result
                            />
                        )}
                        {modalAction === 'duplicate' && (
                            <VenueDuplicateComponent
                                selectedVenue={selectedVenue}
                                UserIDnumber={userID}
                                organizerFirstName={selectedVenue.Organizer_first_name}
                                organizerLastName={selectedVenue.Organizer_last_name}
                                onHide={handleCloseModal}
                                onUpdate={fetchVenuesUpdate} // Pass reference, not result
                            />
                        )}
                        {modalAction === 'delete' && (
                            <p>Are you sure you want to delete this venue?</p>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {modalAction === 'delete' ? (
                            <>
                                <Button variant="secondary" onClick={handleCloseModal}>Cancel</Button>
                                <Button variant="danger" onClick={handleDeleteConfirm}>Yes</Button>
                            </>
                        ) : (
                            <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                        )}
                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};

export default VenueListComponent;

