import React, { useState } from 'react';
import { Carousel, Container } from 'react-bootstrap';
import { FaPhone, FaEnvelope } from 'react-icons/fa'; // Import icons from react-icons
import VideoBackgroundComponent from '../Components/VideoBackgroundComponent';
import './HomeScreen.css'; // Import CSS file for additional styles
import UserProfile from '../Components/UserProfile';


const HomeScreen = () => {
  const [index, setIndex] = useState(0);
  const carouselData = [
    { image: 'https://images.unsplash.com/photo-1598387181032-a3103a2db5b3?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTR8fG11c2ljJTIwYmFuZHxlbnwwfHwwfHx8MA%3D%3D' },
    { image: 'https://www.wallpaperflare.com/static/89/103/605/live-concert-concert-stage-people-wallpaper.jpg' },
    { image: 'https://images.pexels.com/photos/1763075/pexels-photo-1763075.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' },
    { image: 'https://www.shutterstock.com/image-photo/crowd-concert-summer-music-festival-600nw-611045375.jpg' },
  ];

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Container fluid style={{ paddingTop: 20, paddingBottom: 20 }}>
      
      <VideoBackgroundComponent/>
      <h2 className="font-weight-bold text-center" style={{ color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.5)',paddingTop:'25px',paddingBottom:'25px' }}>Featured Bands</h2>

      <Carousel activeIndex={index} onSelect={handleSelect} interval={3000} className="carousel">
        {carouselData.map((item, idx) => (
          <Carousel.Item key={idx}>
            <img
              className="d-block w-100"
              src={item.image}
              alt={`Slide ${idx}`}
              style={{ maxHeight: '500px', objectFit: 'cover' }}
            />
          </Carousel.Item>
        ))}
      </Carousel>

      

    </Container>
  );
};

export default HomeScreen;

