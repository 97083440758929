import React, { useState } from 'react';
import axios from 'axios';
import countriesArray from './CountriesLibrary';
import PaymentScreen from '../Screens/PaymentScreen';
import { AlignStart } from 'react-bootstrap-icons';
import './BandRegistration.css';

import { Elements } from '@stripe/react-stripe-js';
import stripeKey from '../Components/StripeKey';
import { loadStripe } from '@stripe/stripe-js';




const BandRegistration = ({ onClose ,isTesting=true,isMobile=false}) => {
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [bandName, setBandName] = useState('');
  const [musicGenre, setMusicGenre] = useState('');
  const [gigRadius, setGigRadius] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subscription1, setRadioOption1] = useState(true); // default
  const [subscription2, setRadioOption2] = useState(false);
  const [discount, setDiscount] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCountry, setBillingCountry] = useState('US');
  const [contactInformation, setContactInfo] = useState('');
  const [countyInfo, setCounty] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTwoFactorModalVisible, setIsTwoFactorModalVisible] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [userId, setUserId] = useState(null);
  const [subscriptionAmount, setSubscriptionAmount] = useState(999); // default
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [bandVideo, setBandVideo] = useState('');
  const [bandAudio, setBandAudio] = useState('');
  const [bandAudioURL, setBandAudioURL] = useState('');
  const stripePromise = loadStripe(stripeKey);
  const [isProcessing,Processing] = useState(false);
  const [infoFilled,setDetailsFilled] = useState(false);
  const [subscription,SetSubscription] = useState('Band Subscription 1'); //default
  //const [Stripe_CustomerID, setCustomerId] = useState(null);

  const resetForm = () => {
    setSelectedCountry('US');
    setUsername('');
    setFirstName('');
    setLastName('');
    setEmail('');
    setWebsite('');
    setPassword('');
    setRepeatPassword('');
    setBandName('');
    setMusicGenre('');
    setGigRadius('');
    setStreetAddress('');
    setZipCode('');
    setCity('');
    setState('');
    setPhoneNumber('');
    setRadioOption1(true);
    setRadioOption2(false);
    setDiscount('');
    setBillingFirstName('');
    setBillingLastName('');
    setBillingEmail('');
    setBillingPhone('');
    setBillingAddress('');
    setBillingZipCode('');
    setBillingCity('');
    setBillingState('');
    setBillingCountry('US');
    setContactInfo('');
    setCounty('');
    setIsModalVisible(false);
    setIsTwoFactorModalVisible(false);
    setTwoFactorCode('');
    setUserId(null);
    setSubscriptionAmount(999);
    setPaymentConfirmed(false);
    setCardDetails(null);
    setPaymentSuccess(false);
    setPaymentError(null);
    setBandVideo('');
    setBandAudio('');
    setBandAudioURL('');
    Processing(false);
    setDetailsFilled(false);
    SetSubscription('Band Subscription 1');
  };
  const handleCustomerIdGenerated = (id) => {
  //  setCustomerId(id);
    console.log('Customer ID:', id);
    registerBand(id);
  };

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
    // Additional logic after successful payment (e.g., navigate to next screen)
  };

  const handlePaymentFailure = (errorMessage) => {
    setPaymentError(errorMessage);
    // Additional logic on payment failure (e.g., display error message)
  };

  const handleRadio1Press = () => {
    setRadioOption1(true);
    setRadioOption2(false);
    setSubscriptionAmount(999); // 999 cents = $9.99
  };

  const handleRadio2Press = () => {
    setRadioOption2(true);
    setRadioOption1(false);
    setSubscriptionAmount(99); // 99 cents = $0.99
  };

  const copyPersonalToBilling = () => {
    setBillingFirstName(firstName);
    setBillingLastName(lastName);
    setBillingEmail(email);
    setBillingPhone(phoneNumber);
    setBillingAddress(streetAddress);
    setBillingZipCode(zipCode);
    setBillingCity(city);
    setBillingState(state);
    setBillingCountry(selectedCountry);
  };


  const cardPayload = {
    username,
    firstName,
    lastName,
    email,
    bandName,
    password,
    repeatPassword,
    streetAddress,
    zipCode,
    city,
    state,
    phoneNumber,
    billingFirstName,
    billingLastName,
    billingEmail,
    billingPhone,
    billingAddress,
    billingZipCode,
    billingCity,
    billingState,
    billingCountry,
    contactInformation,
    subscription,
    bandVideo,
    bandAudio,
    musicGenre,
    gigRadius,
    website,
    subscription1,
    subscription2,
    subscriptionAmount,
    selectedCountry,
  };

  
  const checkPayload = (payload) => {
    for (const key in payload) {
      if (payload.hasOwnProperty(key)) {
        const value = payload[key];
        
        // Check if the value is a string and is empty after trimming
        if (typeof value === 'string' && value.trim() === '') {
          console.log(`Missing or empty field: ${key}`);
          return false; // If any field is an empty string, return false
        }
  
        // Check if the value is null or undefined
        if (value === null || value === undefined) {
          console.log(`Missing field: ${key}`);
          return false; // If any field is null or undefined, return false
        }
      }
    }
    return true; // All fields are present and filled, return true
  };
  
  
  
  
  

  const registerBand = async (Stripe_CustomerID) => {

    
   
    if (subscription1) {
      SetSubscription('Band Subscription 1');
    } else if (subscription2) {
       SetSubscription('Band Subscription 2');
    } else {
      // Handle case where no subscription is selected
      return;
    }

    
    console.log("Stripe Customer ID " +Stripe_CustomerID);
  
    const bandData = {
      username,
      firstName,
      lastName,
      email,
      bandName,
      password,
      repeatPassword,
      streetAddress,
      zipCode,
      city,
      state,
      phoneNumber,
      billingFirstName,
      billingLastName,
      billingEmail,
      billingPhone,
      billingAddress,
      billingZipCode,
      billingCity,
      billingState,
      billingCountry,
      contactInformation,
      countyInfo,
      subscription,
      bandVideo,
      bandAudio,
      musicGenre,
      gigRadius,
      website,
      subscription1,
      subscription2,
      subscriptionAmount,
      selectedCountry,
      Stripe_CustomerID,
      
    };
  
    console.log("Band Data: " + JSON.stringify(bandData));
    try {
      const response = await fetch('http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/RegisterBand.php', {
        method: 'POST',
        body: JSON.stringify(bandData),
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
      });
  
      // Check if the response is valid JSON
      const responseText = await response.text();
      let data;
      try {
        data = JSON.parse(responseText);
      } catch (e) {
        throw new Error('Invalid JSON response: ' + responseText);
      }
  
      console.log("Response Data: " + JSON.stringify(data));
  
      if (data.success) {
        console.log('Registration successful:', data.message);
        alert('Registration Successful. Payment Confirmed.');
        resetForm();
        Processing(false);
       // onClose();
      } else {
        console.log('Registration failed:', data.message);
        alert('Registration Failed '+data.message);
        resetForm();
        Processing(false);
      }
    } catch (error) {
      console.error('Error registering band:', error.message || error);
      resetForm();
      Processing(false);
    }
  };
  
  

  const handleAudioUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Audio = reader.result;
        setBandAudio(base64Audio);
      };
      reader.readAsDataURL(file);
      const url = URL.createObjectURL(file);
      setBandAudioURL(url);
    }
  };


  const billingDetails = {
    name: billingFirstName + " " + billingLastName, 
    email: billingEmail,
    phone: billingPhone,
    address: {
      city: billingCity,
      country: billingCountry,
      line1: billingAddress,
      line2: '',
      postal_code: billingZipCode, // Corrected field name
    },
  };
  
  

  const  PriceSelected =()=>{

    //let isTesting=true; // in debug

if(isTesting==true)
{
    if(subscription1==true)
       {
      return 'price_1PipzbADRnUTSqmNDZGHOiaS'; // 9.99 yearly
       }

    if(subscription2==true)
    {
       return 'price_1Piq0CADRnUTSqmNwz7s9WmE'; //0.99 monthly
    }

  }

  if(isTesting==false)
  {

    if(subscription1==true)
      {
     return 'price_1PmIGgADRnUTSqmN2rylibmH'; // 9.99 yearly
      }

   if(subscription2==true)
   {
      return 'price_1PmIHCADRnUTSqmNgeQtEt6L'; //0.99 monthly
   }
  }

    // Optionally return a default value or handle cases where no subscription is selected
  return 'default_price_id'; 
 };

 const handleVideoUpload = (e) => {
  const file = e.target.files[0];
  const reader = new FileReader();

  reader.onloadend = () => {
    setBandVideo(reader.result);
  };

  if (file) {
    reader.readAsDataURL(file);
  }
};

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Band Registration</h2>

      {/* Personal Information */}
      <h3 style={styles.sectionTitle}>Personal Information</h3>
      <div style={styles.formContainer}>
        <div style={styles.inputContainer}>
          <label>Name</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}  />
        </div>
        <div style={styles.inputContainer}>
          <label>First Name</label>
          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Last Name</label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Contact Info</label>
          <input type="text" value={contactInformation} onChange={(e) => setContactInfo(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>E-mail</label>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Website</label>
          <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Password</label>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Repeat Password </label>
          <input type="password" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Phone Number</label>
          <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>

        {/* Band Details */}
        <h3 style={styles.sectionTitle}>Band Details</h3>
        <div style={styles.inputContainer}>
          <label>Band Name</label>
          <input type="text" value={bandName} onChange={(e) => setBandName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Music Genre</label>
          <input type="text" value={musicGenre} onChange={(e) => setMusicGenre(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Gig Radius Miles</label>
          <input type="number" value={gigRadius} onChange={(e) => setGigRadius(e.target.value)} />
        </div>
        
        {/* Band Video Upload */}
      <h3 style={styles.sectionTitle}>Band Video</h3>
      <div style={styles.formContainer}>
        <div style={styles.inputContainer}>
          <input type="file" onChange={handleVideoUpload} />
           {/* Video Display */}
      { bandVideo&& (
        <div>
          <h3>Preview</h3>
          <video width="400" controls>
            <source src={bandVideo} type="video/mp4" />
            <source src={bandVideo} type="video/quicktime" /> {/* For .mov files */}
            Your browser does not support the video tag.
          </video>
        </div>
      )}
        </div>
      </div>

      <div style={styles.inputContainer}>
      {isMobile === false && (
  <div>
    <h3 style={styles.sectionTitle}>Band Audio</h3>
    <input type="file" accept="audio/*" onChange={handleAudioUpload} />
  </div>
)}

  {bandAudioURL &&isMobile==false && (
          <div>
            <h3>Audio Preview</h3>
            <audio controls>
              <source src={bandAudioURL} type="audio/mpeg" />
              <source src={bandAudioURL} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
        </div>


        {/* Address */}
        <h3 style={styles.sectionTitle}>Address</h3>
        <div style={styles.inputContainer}>
          <label>Street Address</label>
          <input type="text" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Zip Code</label>
          <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>City</label>
          <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>State</label>
          <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
        </div>

        {/* Country */}
        <div style={styles.inputContainer}>
          <label>Country</label>
          <select value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
            <option value="">Select a country</option>
            {countriesArray.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <button style={styles.button} onClick={copyPersonalToBilling}>
        Copy Personal to Billing
      </button>
        {/* Billing Details */}
        <h3 style={styles.sectionTitle}>Billing Details</h3>
        <div style={styles.inputContainer}>
          <label>Billing First Name</label>
          <input type="text" value={billingFirstName} onChange={(e) => setBillingFirstName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Last Name</label>
          <input type="text" value={billingLastName} onChange={(e) => setBillingLastName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Email</label>
          <input type="text" value={billingEmail} onChange={(e) => setBillingEmail(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Phone</label>
          <input type="text" value={billingPhone} onChange={(e) => setBillingPhone(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Address</label>
          <input type="text" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Zip Code</label>
          <input type="text" value={billingZipCode} onChange={(e) => setBillingZipCode(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing City</label>
          <input type="text" value={billingCity} onChange={(e) => setBillingCity(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing State</label>
          <input type="text" value={billingState} onChange={(e) => setBillingState(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Country</label>
          <select value={billingCountry} onChange={(e) => setBillingCountry(e.target.value)}>
            <option value="">Select a country</option>
            {countriesArray.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        {/* Subscription */}
        <h3 style={styles.sectionTitle}>Subscription</h3>
        <div style={styles.radioContainer}>
          <input type="radio" id="subscription1" name="subscription" checked={subscription1} onChange={handleRadio1Press} />
          <label htmlFor="subscription1">Musicians - Yearly - $9.99 / 12 Months and a $9.99 sign-up fee Yearly Subscriptions for Musicians at the rate of 9.99 per year - $9.99/month</label>
        </div>
        <div style={styles.radioContainer}>
          <input type="radio" id="subscription2" name="subscription" checked={subscription2} onChange={handleRadio2Press} />
          <label htmlFor="subscription2">Musician - Monthly - $0.99 / 1 Month and a $0.99 sign-up fee Monthly subscriptions for Musicians only at the rate of .99 cents per month - $0.99/month</label>
        </div>

        
        <Elements stripe={stripePromise}>
      <PaymentScreen
     onPaymentSuccess={() => {
      // Payment was successful; handle customer ID generation here
      // This should eventually trigger `handleCustomerIdGenerated`
  }}
      onPaymentFailure={handlePaymentFailure}
      customerInfo={billingDetails}
      priceId={PriceSelected()} // Replace with your actual price ID from Stripe
      isAccountDetails={false}
      EmailVerify={billingDetails.email}
      Processing={Processing}
      isProcessing={isProcessing}
      InputDetails={checkPayload(cardPayload)}
      onCustomerIdGenerated={handleCustomerIdGenerated}
      
      
    />
</Elements>
      </div>

     
     
      <div style={styles.bottomContainer}></div>
      
    </div>
  );
};
const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Center items horizontally
      justifyContent: 'flex-start', // Align items to the start vertically
      margin: '20px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#f9f9f9',
      maxWidth: '600px',
      height:'2600px',
      
    },
    title: {
      fontSize: '1.5rem',
      marginBottom: '20px',
    },
    sectionTitle: {
      fontSize: '1.2rem',
      marginTop: '20px',
      marginBottom: '10px',
    },
    formContainer: {
      width: '100%',
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center', // Align inputs to the left
    },
    inputContainer: {
      marginBottom: '10px',
      display: 'flex',
      flexDirection: 'column', // Ensure each input container is a column
    },
    label: {
      marginBottom: '5px', // Add margin below each label for spacing
    },
    registerButton: {
      backgroundColor: '#007bff',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      padding: '10px 20px',
      cursor: 'pointer',
      marginTop: '20px',
    },
    bottomContainer:{

      paddingBottom:'100px',
    }
  };
  

export default BandRegistration;
