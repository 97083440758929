import React from 'react';
import { useAuth } from './AuthContext';
import testAuthData from './AuthContext';

const UserProfile = () => {
  const { authData, logout } = useAuth();

  // For testing purposes, use testAuthData if authData is not available
  const user = authData || testAuthData;

  // Determine the roles display
  let rolesDisplay = '';
  if (user.role) {
    if (Array.isArray(user.role)) {
      rolesDisplay = user.role.join(', ');
    } else if (typeof user.role === 'string') {
      rolesDisplay = user.role;
    } else {
      rolesDisplay = 'Roles not specified';
    }
  } else {
    rolesDisplay = 'Roles not specified';
  }

  return (
    <div>
      <h2>Welcome, {user.user_login}!</h2>
      <p>User ID: {user.user_id}</p>
      <p>Roles: {rolesDisplay}</p>
      {/* Additional user information */}
    </div>
  );
};

export default UserProfile;
