import React, { useState, useEffect } from 'react';
import './App.css';
import EventListComponent from './Components/EventListComponent';
import TopBar from './Components/TopBar';
import MainTopBar from './Components/MainTopBar';
import CalendarComponent from './Components/CalendarComponent';
import HomeScreen from './Screens/Homescreen';
import BottomBarComponent from './Components/BottomBarComponent';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/AccountLogin';
import CalendarScreen from './Screens/CalendarScreen';
import EventListScreen from './Screens/EventListScreen';
import { Modal } from 'react-bootstrap';
import BandRegistrationScreen from './Screens/BandRegistrationScreen';
import OrganizerRegistrationScreen from './Screens/OrganizerRegistrationScreen';
import AccountScreen from './Screens/AccountScreen';
import MobileBandRegistration from './Components/MobileBandRegistration';
import MobileOrganizerRegistration from './Components/MobileOrganizerRegistration';
import MobileBandAccountDetails from './Components/MobileBandAccountDetails';
import MobileOrganizerAccountDetails from './Components/MobileOrganizerAccountDetails';

function App() {
  const [view, setView] = useState('home');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showBandRegistrationModal, setShowBandRegistrationModal] = useState(false);
  const [showOrganizerRegistrationModal, setShowOrganizerRegistrationModal] = useState(false);
  const [lastActiveView, setLastActiveView] = useState('home');

  const authData = JSON.parse(localStorage.getItem('authData'));

  const isTesting = useState(true); // Use false for REAL MONEY ; true for fake card testing for Registration

  useEffect(() => {
    const handleInitialHashNavigation = () => {
      const hash = window.location.hash.substr(1).toLowerCase(); // Convert hash to lowercase
  
      if (hash === 'bandregistration') {
        setView('BandRegistration');
      } else if (hash === 'organizerregistration') {
        setView('OrganizerRegistration');
      } else if (hash === 'login') {
        setView('login');
      } else if (hash === 'mobilebandregistration') {
        setView('MobileBandRegistration');
      } else if (hash === 'mobileorganizerregistration') {
        setView('MobileOrganizerRegistration');
      } else if (hash === 'mobilebandaccountdetails') {
        setView('MobileBandAccountDetails');
      }else if (hash === 'mobileorganizeraccountdetails') {
        setView('MobileOrganizerAccountDetails');
      }
    };
  
    // Handle initial hash navigation
    handleInitialHashNavigation();
  
    // Listen to hash changes
    window.addEventListener('hashchange', handleInitialHashNavigation);
  
    return () => {
      window.removeEventListener('hashchange', handleInitialHashNavigation);
    };
  }, [authData, view]);

  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
    if (!authData) {
      setView(lastActiveView);
    }
  };

  const handleShowLoginModal = () => {
    setLastActiveView(view);
    setView('login');
    setShowLoginModal(true);
  };

  const handleCloseBandRegistrationModal = () => {
    setShowBandRegistrationModal(false);
  };

  const handleShowBandRegistrationModal = () => {
    setShowBandRegistrationModal(true);
  };

  const handleCloseOrganizerRegistrationModal = () => {
    setShowOrganizerRegistrationModal(false);
  };

  const handleShowOrganizerRegistrationModal = () => {
    setShowOrganizerRegistrationModal(true);
  };

  const handleChangeView = (newView) => {
    if (newView === 'login') {
      handleShowLoginModal();
    } else {
      setView(newView);
      window.location.hash = newView.toLowerCase();
    }
  };

  const onLogin = () => {
    setView('Account');
    window.location.hash = 'account';
  };

  return (
    <div className="App" style={{ backgroundColor: '#e2e6e9' }}>
      {view !== 'MobileBandRegistration'&&view !== 'MobileOrganizerRegistration'&&view !== 'MobileOrganizerAccountDetails'&&view !== 'MobileBandAccountDetails'&&<MainTopBar
        setView={handleChangeView}
        handleShowBandRegistrationModal={handleShowBandRegistrationModal}
        handleShowOrganizerRegistrationModal={handleShowOrganizerRegistrationModal}
      />}

<Modal 
  show={showLoginModal} 
  onHide={handleCloseLoginModal} 
  centered 
  dialogClassName="full-screen-modal" /* Apply the custom class */
>
  <Modal.Header closeButton>
  </Modal.Header>
  <Modal.Body>
    <Login onLogin={onLogin} />
  </Modal.Body>
</Modal>


      <Modal show={showBandRegistrationModal} onHide={handleCloseBandRegistrationModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Band Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BandRegistrationScreen isTesting={isTesting} />
        </Modal.Body>
      </Modal>

      <Modal show={showOrganizerRegistrationModal} onHide={handleCloseOrganizerRegistrationModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Organizer Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrganizerRegistrationScreen isTesting={isTesting}/>
        </Modal.Body>
      </Modal>

      <div className="main-content">
        {view !== 'home' && view !== 'login' && view !== 'BandRegistration' && view !== 'OrganizerRegistration' && view !== 'Account' &&view !== 'MobileBandRegistration'&&view !== 'MobileOrganizerRegistration'&&view !== 'MobileOrganizerAccountDetails'&&view !== 'MobileBandAccountDetails'&& <TopBar view={view} setView={setView} />}
        {view === 'list' && <EventListScreen key="eventList" />}
        {view === 'calendar' && <CalendarScreen key="calendar" />}
        {view === 'home' && <HomeScreen key="homeScreen" />}
        {view === 'Account' && <AccountScreen />}
        {view !== 'list' && view !== 'calendar' && view !== 'login' && view !== 'BandRegistration' && view !== 'OrganizerRegistration' && view !== 'Account' &&view !== 'MobileBandRegistration'&&view !== 'MobileOrganizerRegistration'&&view !== 'MobileOrganizerAccountDetails'&&view !== 'MobileBandAccountDetails'&& (
          <BottomBarComponent view={view} setView={setView} />
        )}
        {view === 'MobileBandAccountDetails' && <MobileBandAccountDetails />}
        {view === 'MobileOrganizerAccountDetails' && <MobileOrganizerAccountDetails/>}
        {view === 'MobileBandRegistration' && <MobileBandRegistration isTesting={true} />}
        {view === 'MobileOrganizerRegistration' && <MobileOrganizerRegistration isTesting={true} />}
        {view === 'BandRegistration' && <BandRegistrationScreen isTesting={true} />}
        {view === 'OrganizerRegistration' && <OrganizerRegistrationScreen isTesting={true} />}
        {view === 'login' && lastActiveView === 'home' && <HomeScreen key="homeScreen" />}
        {view === 'login' && lastActiveView === 'list' && <EventListScreen key="eventList" />}
        {view === 'login' && lastActiveView === 'calendar' && <CalendarScreen key="calendar" />}
      </div>
    </div>
  );
}

export default App;

