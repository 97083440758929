import React, { useState, useEffect } from 'react';
import axios from 'axios';
import countriesArray from './CountriesLibrary';
import PaymentScreen from '../Screens/PaymentScreen';
import './BandRegistration.css';
import stripeKey from '../Components/StripeKey';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ResetPassword from './ResetPassword';

import { Modal, Button,Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import GetSubscriptionInfo from './GetSubscriptionInfo';
import CancelSubscriptionAndDeleteAccount from './CancelSubscriptionAndDeleteAccount';

const BandAccountDetails = ({ userid, onClose }) => {
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [bandName, setBandName] = useState('');
  const [musicGenre, setMusicGenre] = useState('');
  const [gigRadius, setGigRadius] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [subscription1, setRadioOption1] = useState(true); // default
  const [subscription2, setRadioOption2] = useState(false);
  const [subscription,setSubscription]=useState('');
  const [discount, setDiscount] = useState('');
  const [billingFirstName, setBillingFirstName] = useState('');
  const [billingLastName, setBillingLastName] = useState('');
  const [billingEmail, setBillingEmail] = useState('');
  const [billingPhone, setBillingPhone] = useState('');
  const [billingAddress, setBillingAddress] = useState('');
  const [billingZipCode, setBillingZipCode] = useState('');
  const [billingCity, setBillingCity] = useState('');
  const [billingState, setBillingState] = useState('');
  const [billingCountry, setBillingCountry] = useState('US');
  const [contactInformation, setContactInfo] = useState('');
  const [countyInfo, setCounty] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isTwoFactorModalVisible, setIsTwoFactorModalVisible] = useState(false);
  const [twoFactorCode, setTwoFactorCode] = useState('');
  const [userId, setUserId] = useState(null);
  const [subscriptionAmount, setSubscriptionAmount] = useState(999); // default
  const [paymentConfirmed, setPaymentConfirmed] = useState(false);
  const [cardDetails, setCardDetails] = useState(null);

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [bandVideo, setBandVideo] = useState('');
  const [Band_audio, setBandAudio] = useState('');
  const [bandAudioURL, setBandAudioURL] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [runonce,SetButtonLimit]=useState(false);
  const [isProcessing,Processing] = useState(false);
  const [infoFilled,setDetailsFilled] = useState(false);
  const [loading, setLoading] = useState(true);

const stripePromise = loadStripe(stripeKey);

const [stripeCustomerID, setStripeCustomerID] = useState(null);
const [CustomerIDerror, setCustomerIDError] = useState(null);

  useEffect(() => {

    
    const fetchBandDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetAccountDetailsforBand.php?Band_ID=${userid}`);
        const bandData = response.data.band;
        console.log("USER: "+userid+" ACCOUNT :"+JSON.stringify(bandData));
        setUserId(userid);
        setUsername(bandData.Band_login);
        setFirstName(bandData.Musician_first_name);
        setLastName(bandData.Musician_last_name);
        setEmail(bandData.Band_email);
        setBandName(bandData.Band_name);
        setStreetAddress(bandData.Band_address);
        setZipCode(bandData.Band_zipcode);
        setCity(bandData.Band_city);
        setState(bandData.Band_state);
        setPhoneNumber(bandData.Band_Phone);
        setBillingFirstName(bandData.Billing_FirstName);
        setBillingLastName(bandData.Billing_LastName);
        setBillingEmail(bandData.Billing_Email);
        setBillingPhone(bandData.Billing_Phone);
        setBillingAddress(bandData.Billing_Address);
        setBillingZipCode(bandData.Billing_Zipcode);
        setBillingCity(bandData.Billing_City);
        setBillingState(bandData.Billing_State);
        setBillingCountry(bandData.Billing_Country);
        setContactInfo(bandData.Contact_Information);
        setCounty(bandData.CountyInfo);
        setSubscriptionAmount(bandData.Subscription_Amount);
        setSelectedCountry(bandData.Band_country);
        setMusicGenre(bandData.Band_genre);
        setGigRadius(bandData.Gig_radius);
        setWebsite(bandData.Website);
        setRadioOption1(checkSubscription(bandData.Subscription_1));
        setRadioOption2(checkSubscription(bandData.Subscription_2));
        setBandVideo(bandData.Band_video);
        setBandAudio(bandData.Band_audio);
        if(bandData!=null)
        {
          setLoading(false);
        }
        fetchStripeCustomerID(userid);
        // Set the audio URL
      if (bandData.Band_audio) {
        setBandAudioURL(bandData.Band_audio);
      }
      } catch (error) {
        console.error('Error fetching band details:', error);
      }
    };
    fetchBandDetails();
  }, [userid]);

  const checkSubscription1= (data)=>{
  if(data=="true")
  {
    setRadioOption1(true);

  }

  

  if(data=="false")
    {
      setRadioOption1(false);
  
    }
    

  }

  useEffect(() => {
    console.log("subscription1: ", subscription1); // This will log the correct state value
    console.log("subscription2: ", subscription2); // This will log the correct state value
  }, [subscription1,subscription2]);

  const checkSubscription= (data)=>{
    
  
    if(data=="")
      {
        return false;
    
      }else{

        return true;
      }

      
      
  
    }


  const updateBandDetails = async () => {
   
    
    console.log("Update Band Details");
    console.log("subscription 1 "+subscription1);
    console.log("subscription  2"+subscription2);
    
    if (subscription1==true) {
     setSubscription('Band Subscription 1');
    }else{
     setSubscription('Band Subscription 2');
    } 

    if(runonce==true)
    {
        return;
    }
    
    if(subscription=='') {
        console.log("no subscription is selected");
      // Handle case where no subscription is selected
      return;
    }else{

        SetButtonLimit(true);
        alert("Saving..");

    }


    console.log("Update Band Details Did it make it past the let");

    console.log("Billing First Name: "+billingFirstName);

    console.log("Subscription: "+subscription);
    console.log("Subscription 1: "+subscription1);
    console.log("Subscription 2: "+subscription2);

    console.log("Band Video: "+bandVideo);
    console.log("Band Audio: "+Band_audio);

    const bandData = {
        Band_ID:userid,
      Band_login: username,
      Musician_first_name: firstName,
      Musician_last_name: lastName,
      Band_email: email,
      Band_name: bandName,      
      Band_address: streetAddress,
      Band_zipcode: zipCode,
      Band_city: city,
      Band_state: state,
      Band_Phone: phoneNumber,
      Billing_FirstName: billingFirstName,
      Billing_LastName: billingLastName,
      Billing_Email: billingEmail,
      Billing_Phone: billingPhone,
      Billing_Address: billingAddress,
      Billing_Zipcode: billingZipCode,
      Billing_City: billingCity,
      Billing_State: billingState,
      Billing_Country: billingCountry,
      Contact_Information: email,
      Subscription: subscription,
      Band_video: bandVideo,
      Band_audio:Band_audio,
      Band_genre: musicGenre,
      Gig_radius: gigRadius,
      Website: website,
      Subscription_1: subscription1,
      Subscription_2: subscription2,
      Subscription_Amount: subscriptionAmount,
      Band_country: selectedCountry,
    };
    console.log("Update Band Details Did it make it here?");
    try {
        const response = await axios.post(
            'http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/UpdateBandAccountDetails.php',
            bandData,
        { headers: { 'Content-Type': 'application/json' } }
    );
   
      const result = response.data;
      if (result.status=="success") {
        console.log('Update successful:', result.message);
      //  alert('Update Successful.');
      Processing(false);
        setSuccessMessage(result.message);
        SetButtonLimit(false);
       // onClose();
      } else if(result.status=="error") {
        setErrorMessage(result.message);
        console.log("result:"+JSON.stringify(result));
        console.log('Update failed:', result.message);
        console.log("response input debug: ",result.received_data);
        SetButtonLimit(false);
        Processing(false);
       
      }
    } catch (error) {
        

      console.error('Error updating band details:', error);
      setErrorMessage('An error occurred while updating the band details. Please try again later.');
      SetButtonLimit(false);
      Processing(false);
    }
  };

  const handlePaymentSuccess = () => {
    setPaymentSuccess(true);
    // Additional logic after successful payment (e.g., navigate to next screen)
  };

  const handlePaymentFailure = (errorMessage) => {
    setPaymentError(errorMessage);
    // Additional logic on payment failure (e.g., display error message)
  };

  const handleRadio1Press = () => {
    setRadioOption1(true);
    setRadioOption2(false);
    setSubscriptionAmount(999); // 999 cents = $9.99
  };

  const handleRadio2Press = () => {
    setRadioOption2(true);
    setRadioOption1(false);
    setSubscriptionAmount(99); // 99 cents = $0.99
  };

  const copyPersonalToBilling = () => {
    setBillingFirstName(firstName);
    setBillingLastName(lastName);
    setBillingEmail(email);
    setBillingPhone(phoneNumber);
    setBillingAddress(streetAddress);
    setBillingZipCode(zipCode);
    setBillingCity(city);
    setBillingState(state);
    setBillingCountry(selectedCountry);
  };

  const handleVideoUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setBandVideo(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const billingDetails = {
    name: `${billingFirstName} ${billingLastName}`, // Replace with your logic
    email: billingEmail,
    phone: billingPhone,
    address: {
      city: billingCity,
      country: billingCountry,
      line1: billingAddress,
      line2: '',
      postalCode: billingZipCode,
    },
  };

  const handleAudioUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Audio = reader.result;
        setBandAudio(base64Audio);
      };
      reader.readAsDataURL(file);
      const url = URL.createObjectURL(file);
      setBandAudioURL(url);
    }
  };

  const handleCloseModal = () => {
    setErrorMessage(null);
  };

  const handleCloseSuccessModal = () => {
    setSuccessMessage(null);
  };

  const  PriceSelected =()=>{
     if(subscription1==true)
        {
       return 'price_1PipzbADRnUTSqmNDZGHOiaS'; // 9.99 yearly
        }

     if(subscription2==true)
     {
        return 'price_1Piq0CADRnUTSqmNwz7s9WmE'; //0.99 monthly
     }
  };

  const fetchStripeCustomerID = async (user) => {
    const formData = new FormData();
    formData.append('user_id', user);
    formData.append('role', 'subscriber');
    
  
    try {
      const response = await axios.post(
        'http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetStripeCustomerID.php',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      
      console.log("Fetched response Stripe Customer ID " + JSON.stringify(response.data));
      
  
      console.log("Fetched response Stripe Customer ID "+JSON.stringify(response.data));
  
      console.log("Fetched Customer ID :"+response.data.Stripe_CustomerID);
  
      // Handle the response and extract Stripe_CustomerID
      if (response.data.Stripe_CustomerID) {
        setStripeCustomerID(response.data.Stripe_CustomerID);
      } else if (response.data.error) {
        setCustomerIDError(response.data.error);
      }
    } catch (err) {
      setCustomerIDError('An error occurred while fetching data.');
    }
  };

  return (
    <div style={styles.container}>
      {loading ? (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          
        </Spinner>
      </div>
    ) : (
      <>
      </>
    )}
      <h2 style={styles.title}>Band Account Details</h2>

      {/* Personal Information */}
      <h3 style={styles.sectionTitle}>Personal Information</h3>
      <div style={styles.formContainer}>
        <div style={styles.inputContainer}>
          <label>Name</label>
          <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>First Name</label>
          <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Last Name</label>
          <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Email</label>
          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Website</label>
          <input type="text" value={website} onChange={(e) => setWebsite(e.target.value)} />
        </div>
        <ResetPassword role={'subscriber'}/>
      </div>

      {/* Band Information */}
      <h3 style={styles.sectionTitle}>Band Information</h3>
      <div style={styles.formContainer}>
        <div style={styles.inputContainer}>
          <label>Band Name</label>
          <input type="text" value={bandName} onChange={(e) => setBandName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Music Genre</label>
          <input type="text" value={musicGenre} onChange={(e) => setMusicGenre(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Gig Radius</label>
          <input type="text" value={gigRadius} onChange={(e) => setGigRadius(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Street Address</label>
          <input type="text" value={streetAddress} onChange={(e) => setStreetAddress(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>ZIP Code</label>
          <input type="text" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>City</label>
          <input type="text" value={city} onChange={(e) => setCity(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>State</label>
          <input type="text" value={state} onChange={(e) => setState(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Country</label>
          <select value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
            {countriesArray.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.inputContainer}>
          <label>Phone Number</label>
          <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
        </div>
      </div>

      {/* Billing Information */}
      <h3 style={styles.sectionTitle}>Billing Information</h3>
      <div style={styles.formContainer}>
        <div style={styles.inputContainer}>
          <label>Billing First Name</label>
          <input type="text" value={billingFirstName} onChange={(e) => setBillingFirstName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Last Name</label>
          <input type="text" value={billingLastName} onChange={(e) => setBillingLastName(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Email</label>
          <input type="text" value={billingEmail} onChange={(e) => setBillingEmail(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Phone</label>
          <input type="text" value={billingPhone} onChange={(e) => setBillingPhone(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Address</label>
          <input type="text" value={billingAddress} onChange={(e) => setBillingAddress(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing ZIP Code</label>
          <input type="text" value={billingZipCode} onChange={(e) => setBillingZipCode(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing City</label>
          <input type="text" value={billingCity} onChange={(e) => setBillingCity(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing State</label>
          <input type="text" value={billingState} onChange={(e) => setBillingState(e.target.value)} />
        </div>
        <div style={styles.inputContainer}>
          <label>Billing Country</label>
          <select value={billingCountry} onChange={(e) => setBillingCountry(e.target.value)}>
            {countriesArray.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Subscription Options */}
      <h3 style={styles.sectionTitle}>Subscription</h3>
      <div style={styles.subscriptionContainer}>
        <GetSubscriptionInfo stripeCustomerID={stripeCustomerID} role={'subscriber'}/>
      </div>

      {/* Band Video Upload */}
      <h3 style={styles.sectionTitle}>Band Video</h3>
      <div style={styles.formContainer}>
        <div style={styles.inputContainer}>
          <input type="file" onChange={handleVideoUpload} />
           {/* Video Display */}
      { bandVideo&& (
        <div>
          <h3>Preview</h3>
          <video width="400" controls>
            <source src={bandVideo} type="video/mp4" />
            <source src={bandVideo} type="video/quicktime" /> {/* For .mov files */}
            Your browser does not support the video tag.
          </video>
        </div>
      )}
        </div>
      </div>

      <div style={styles.inputContainer}>
      <h3 style={styles.sectionTitle}>Band Audio</h3>
  <input type="file" accept="audio/*" onChange={handleAudioUpload} />
  {bandAudioURL && (
          <div>
            <h3>Audio Preview</h3>
            <audio controls>
              <source src={bandAudioURL} type="audio/mpeg" />
              <source src={bandAudioURL} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
</div>
      <Elements stripe={stripePromise}>
      <PaymentScreen
      onPaymentSuccess={handlePaymentSuccess}
      onPaymentFailure={handlePaymentFailure}
      customerInfo={billingDetails}
      priceId={PriceSelected} // Replace with your actual price ID from Stripe
      isAccountDetails={true}
      Processing={Processing}
      isProcessing={isProcessing}
      infoFilled={infoFilled}
    />
</Elements>
      <button style={styles.button} onClick={copyPersonalToBilling}>
        Copy Personal to Billing
      </button>

      <button style={styles.button} onClick={updateBandDetails}>
        Update Account Details
      </button>

      <CancelSubscriptionAndDeleteAccount user_id={userId} role={'subscriber'} Stripe_CustomerID={stripeCustomerID} />

      <button style={styles.button} onClick={onClose}>
        Close
      </button>

      

      {/* Two Factor Authentication Modal */}
      {isTwoFactorModalVisible && (
        <div className="twoFactorModal">
          <h2>Two Factor Authentication</h2>
          <label>Enter Code:</label>
          <input type="text" value={twoFactorCode} onChange={(e) => setTwoFactorCode(e.target.value)} />
          <button onClick={() => setIsTwoFactorModalVisible(false)}>Submit</button>
        </div>
      )}

       {/* Error Modal */}
       <Modal show={!!errorMessage} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* success Modal */}
      <Modal show={!!successMessage} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>{successMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f5f5f5',
    borderRadius: '8px',
    maxWidth: '800px',
    margin: '0 auto',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  formContainer: {
    display: 'grid',
    gap: '10px',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  subscriptionContainer: {
    display: 'flex',
    gap: '20px',
  },
};

export default BandAccountDetails;
