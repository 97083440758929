import React from 'react';
import './BottomBarComponent.css';

const BottomBarComponent = () => {
  return (
    <div className="bottom-bar">
      <div className="section">
        <h4 className="section-title">Site Info</h4>
        <div className="underline"></div>
        <p className="section-text" style={{textAlign:'left'}}>
          Helping Musicians find gigs and gigs find musicians!<br />
          We make connections between gigs and bands!<br />
          We bring life to Live entertainments!<br />
          Dream your life!<br />
          Love your life!<br />
          Live on your Dreams!
        </p>
      </div>

      <div className="section">
        <h4 className="section-title">Site Map</h4>
        <div className="underline"></div>
        <div className="bottombutton-group">
          <button className="custom-button"> Home</button>
          <button className="custom-button">About Us</button>
          <button className="custom-button">Contact Us</button>
          <button className="custom-button">FAQs</button>
          <button className="custom-button">Privacy Policy</button>
          <button className="custom-button">Terms Of Use</button>
        </div>
      </div>

      <div className="section">
        <h4 className="section-title">Important Link</h4>
        <div className="underline"></div>
        <div className="bottombutton-group">
          <button className="custom-button">Events</button>
          <button className="custom-button">Venues</button>
          <button className="custom-button"> Band</button>
          <button className="custom-button"> Gig Slot</button>
          <button className="custom-button"> Post an Event</button>
        </div>
      </div>

      <div className="section">
        <h4 className="section-title">Contact Info</h4>
        <div className="underline"></div>
        <div className="bottombutton-group">
          <button className="custom-button"> 12 Durbin Park, FL 12004, USA.</button>
          <button className="custom-button"> +1 904-419-7701</button>
          <button className="custom-button"> Support@mygigslot.com</button>
        </div>
      </div>
    </div>
  );
};

export default BottomBarComponent;
