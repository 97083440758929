import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { List, Calendar } from 'react-bootstrap-icons';

const TopBar = ({ view, setView }) => {
  return (
    <Navbar bg="light" expand="lg">
      <Nav className="mr-auto">
        <Nav.Link onClick={() => setView('list')}>
          <List /> List
        </Nav.Link>
        <Nav.Link onClick={() => setView('calendar')}>
          <Calendar /> Calendar
        </Nav.Link>
      </Nav>
    </Navbar>
  );
};

export default TopBar;

