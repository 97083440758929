import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button, Spinner } from 'react-bootstrap';

const CancelSubscriptionAndDeleteAccountMobile = ({ user_id, role, Stripe_CustomerID }) => {
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleConfirm = async () => {
        if (!user_id || !role || !Stripe_CustomerID) {
            alert('Missing required information.');
            return;
        }
        setLoading(true);
        try {
            // Create FormData for canceling the subscription
            const cancelFormData = new FormData();
            cancelFormData.append('customer_id', Stripe_CustomerID);

            // Send request to cancel the subscription
            const cancelResponse = await axios.post(
                'http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/SubscriptionCancel.php',
                cancelFormData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            );

            if (cancelResponse.data.status === 'success') {
                // Create FormData for deleting the user
                const deleteFormData = new FormData();
                deleteFormData.append('user_id', user_id);
                deleteFormData.append('role', role);

                // Send request to delete the user
                const deleteResponse = await axios.post(
                    'http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/DeleteUser.php',
                    deleteFormData,
                    {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    }
                );

                if (deleteResponse.data.status === 'success') {
                    // Send a message to the React Native WebView on success
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(
                            JSON.stringify({ status: 'deleted', message: 'Account deleted and subscription cancelled.' })
                        );
                    }
                } else {
                    alert(deleteResponse.data.message || 'Error deleting user.');
                }
            } else {
                alert(cancelResponse.data.message || 'Error cancelling subscription.');
            }
        } catch (error) {
            alert('An error occurred while processing your request.');
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    return (
        <>
            <div style={styles.button}>
                <Button variant="danger" onClick={handleShow}>
                    Cancel Subscription and Delete Account
                </Button>
            </div>

            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the account and cancel the subscription?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={handleConfirm} disabled={loading}>
                        {loading ? <Spinner animation="border" size="sm" /> : 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

const styles = {
    button: {
        paddingTop: '50px',
        paddingBottom: '100px'
    }
};

export default CancelSubscriptionAndDeleteAccountMobile;
