import React from 'react';
import { Button } from 'react-bootstrap';
import OrganizerRegistration from '../Components/OrganizerRegistration';

const OrganizerRegistrationScreen = ({isTesting=true}) => {
    const handleRegistration = () => {
        // Handle registration logic here
    };

    return (
        <div style={{ padding: '20px', backgroundColor: 'white', borderRadius: '8px' }}>
            <OrganizerRegistration isTesting={isTesting}/>
        </div>
    );
};

export default OrganizerRegistrationScreen;

