import React from 'react';
import EventListComponent from '../Components/EventListComponent';
import { useAuth } from '../Components/AuthContext';

const EventListScreen = () => {
    const { authData, logout } = useAuth();

  return (
    <div>
      <EventListComponent Role={authData?.role} AuthData={authData} />
    </div>
  );
};

export default EventListScreen;
