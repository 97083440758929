import React from 'react';
import BandRegistration from './BandRegistration';

const MobileBandRegistration = ({ isTesting = true }) => {
  return (
    <div style={styles.container}>
      <BandRegistration isTesting={isTesting} isMobile={true} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: '20px',
    backgroundColor: '#fff',
    height: '100vh', // Adjust as needed
  },
};

export default MobileBandRegistration;

