import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Alert, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import countriesArray from './CountriesLibrary';
import generateGeolocation from './generateGeolocation';
import './GigCreatorComponent.css';

const GigCreatorComponent = ({ UserIDnumber }) => {
  const [venues, setVenues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState('');
  const [gigTitle, setGigTitle] = useState('');
  const [gigAddress, setGigAddress] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [description, setDescription] = useState('');
  const [genre, setGenre] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [gigImage, setGigImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingVenues, setLoadingVenues] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State for success modal
  const [showErrorModal, setShowErrorModal] = useState(false); // State for error modal
  const [errorMessage, setErrorMessage] = useState(''); // State for error messages

  const formatDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    
    return { formattedDateTime, formattedTime };
  };
  
  

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await fetch(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/GetVenuesByOrganizerID.php?OrganizerID=${UserIDnumber}`);
        const data = await response.json();
        setVenues(data);
       // console.log("Data: "+JSON.stringify(data));
      } catch (error) {
        setFetchError('Error fetching venues');
        console.error('Error fetching venues:', error);
      } finally {
        setLoadingVenues(false);
      }
    };

    fetchVenues();
  }, [UserIDnumber]);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setGigImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleSaveGig = async () => {
    if (!gigTitle || !gigAddress || !zipcode || !description) {
      setErrorMessage('Please fill in all required fields.');
      setShowErrorModal(true);
      return;
    }

    setIsSaving(true);
    try {
      const geolocationData = await generateGeolocation(gigAddress, selectedCountry, zipcode);
      const gigData = {
        Gig_data: startDate,
        Gig_created_date: new Date(),
        Gig_start_date: formatDateTime(startDate).formattedDateTime,
        Gig_end_date:  formatDateTime(endDate).formattedDateTime,
        Organizer_id: UserIDnumber,
        Band_id: null, 
        Venue: venues.find(venue => venue.Venue_ID === parseInt(selectedVenue))?.Venue_name || '',
        VenueID: selectedVenue,
        Gig_Title: gigTitle,
        Gigs_start_time: formatDateTime(startDate).formattedTime,
        Gigs_end_time: formatDateTime(endDate).formattedTime,
        Gig_Status: 'Scheduled',
        Gig_GeolocationData: JSON.stringify({
          latitude: geolocationData.geolocation_lat,
          longitude: geolocationData.geolocation_long
        }),
        Gig_Image: gigImage,
        Gig_Confirmed_Registrations: "",
        Gig_Description: description,
        Gig_Location_Address: gigAddress,
        Gig_Zipcode: zipcode,
        Gig_Countrycode: selectedCountry,
        Geolocation_lat: geolocationData.geolocation_lat,
        Geolocation_long: geolocationData.geolocation_long,
        Geolocation_formatted_address: geolocationData.geolocation_formatted_address,
        Geolocation_city: geolocationData.geolocation_city,
        Geolocation_state_short: geolocationData.geolocation_state_short,
        Geolocation_state_long: geolocationData.geolocation_state_long,
        Geolocation_postcode: geolocationData.geolocation_postcode,
        Geolocation_country_short: geolocationData.geolocation_country_short,
        Geolocation_country_long: geolocationData.geolocation_country_long,
        Gig_Views: '0'
      };
      console.log("GigData: "+JSON.stringify(gigData));
      //console.log("GIG Start Time: "+ formatDateTime(startDate).formattedTime+" Gig End Time: "+ formatDateTime(endDate).formattedTime+" Gig Start Date:"+gigData.Gig_start_date+" Gig End Date:"+gigData.Gig_end_date+"Gig Image:"+gigData.Gig_Image );

      const response = await fetch('http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/CreateGig.php', {
        method: 'POST',
       
        body: JSON.stringify(gigData),
        mode: 'cors' // Add this line
      });
      const data = await response.json();

      if (response.ok) {
        // Reset all input fields
        setGigTitle('');
        setGigAddress('');
        setZipcode('');
        setSelectedCountry('');
        setDescription('');
        setGenre('');
        setStartDate(new Date());
        setEndDate(new Date());
        setGigImage(null);
        setSelectedVenue('');

        // Show success modal
        setShowSuccessModal(true);
      } else {
        throw new Error(data.message || 'Gig creation failed');
      }
    } catch (error) {
      console.error('Error saving gig:', error);
      setErrorMessage(error.message);
      setShowErrorModal(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleCloseErrorModal = () => setShowErrorModal(false);

  if (loadingVenues) {
    return <Container><p>Loading venues...</p></Container>;
  }

  if (fetchError) {
    return <Container><Alert variant="danger">{fetchError}</Alert></Container>;
  }

  return (
    <Container style={{ paddingTop: '20px' }}>
      <Form>
        <Form.Group as={Row} controlId="gigTitle">
          <Form.Label column sm={2}>* Gig Title:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={gigTitle} onChange={(e) => setGigTitle(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="venues">
          <Form.Label column sm={2}>Venues:</Form.Label>
          <Col sm={10}>
            <Form.Control as="select" value={selectedVenue} onChange={(e) => setSelectedVenue(e.target.value)}>
              <option value="">Select a venue</option>
              {venues.map((venue) => (
                <option key={venue.Venue_ID} value={venue.Venue_ID}>{venue.Venue_name}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="gigAddress">
          <Form.Label column sm={2}>Location * Example: "Street Address, City, State 'FL'":</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={gigAddress} onChange={(e) => setGigAddress(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="zipcode">
          <Form.Label column sm={2}>Zip Code *:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={zipcode} onChange={(e) => setZipcode(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="country">
          <Form.Label column sm={2}>Country:</Form.Label>
          <Col sm={10}>
            <Form.Control as="select" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
              {countriesArray.map((country) => (
                <option key={country.code} value={country.code}>{country.name}</option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="description">
          <Form.Label column sm={2}>Description *:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="genre">
          <Form.Label column sm={2}>Genre:</Form.Label>
          <Col sm={10}>
            <Form.Control type="text" value={genre} onChange={(e) => setGenre(e.target.value)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="gigImage">
          <Col sm={{ span: 10, offset: 2 }}>
            <Button variant="secondary" onClick={() => document.getElementById('gigImageInput').click()} className="save-image-button">Choose an image</Button>
            <Form.Control type="file" id="gigImageInput" onChange={handleImageUpload} style={{ display: 'none' }} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="startDate">
          <Form.Label column sm={2}>Start Date *:</Form.Label>
          <Col sm={10}>
            <DatePicker selected={startDate}  showTimeSelect
              dateFormat="Pp"
              className="form-control" onChange={(date) => setStartDate(date)} />
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="endDate">
          <Form.Label column sm={2}>End Date:</Form.Label>
          <Col sm={10}>
            <DatePicker selected={endDate}  showTimeSelect
              dateFormat="Pp"
              className="form-control" onChange={(date) => setEndDate(date)} />
          </Col>
        </Form.Group>

        <Button variant="primary" onClick={handleSaveGig} disabled={isSaving}>
          {isSaving ? 'Saving...' : 'Save Gig'}
        </Button>
      </Form>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Gig created successfully!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showErrorModal} onHide={handleCloseErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseErrorModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default GigCreatorComponent;



