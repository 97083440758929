import React, { useState } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const ResetPassword = ({ userId, role }) => {
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [message, setMessage] = useState('');
    const [showModal, setShowModal] = useState(false);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (newPassword !== repeatPassword) {
            setMessage('Passwords do not match.');
            handleShow();
            return;
        }

        let urltail = 'UpdateBandPassword.php'; // Default to 'UpdateBandPassword.php'
        if (role === 'organizer') {
            urltail = 'UpdateOrganizerPassword.php';
        }

        try {
            const response = await axios.post(`http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/${urltail}`, {
                userId,
                newPassword,
                repeatPassword
            });
            setMessage(response.data.message);
            handleShow();
        } catch (error) {
            console.error('Error updating password:', error);
            setMessage('Error updating password.');
            handleShow();
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        New Password:
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                            style={{ marginLeft: '10px' }}
                        />
                    </label>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        Repeat Password:
                        <input
                            type="password"
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            required
                            style={{ marginLeft: '10px' }}
                        />
                    </label>
                </div>
                <button type="submit" style={{ padding: '10px 20px' }}>Update Password</button>
            </form>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ResetPassword;
