import React, { useState } from 'react';
import { Navbar, Nav, Button, NavDropdown } from 'react-bootstrap';
import './MainTopBar.css';
import { useAuth } from './AuthContext';

const MainTopBar = ({ setView }) => {
  const [activeItem, setActiveItem] = useState('home');
  const { authData, logout } = useAuth();
  const isLoggedIn = !!authData;

  const handleLogout = async () => {
    const logoutEndpoint = 'http://dev1-env.eba-ai3qz2at.us-east-1.elasticbeanstalk.com/Logout.php';

    try {
      const response = await fetch(logoutEndpoint);
      if (response.ok) {
        console.log('Logout successful');
        logout();
      } else {
        console.error('Logout failed');
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleNavItemClick = async (view) => {
    setActiveItem(view);
    if (view === 'logout' && isLoggedIn) {
      await handleLogout();
      setView('home'); // Redirect to home after logout
    } else if (view === 'BandRegistration') {
      setView(view);
      window.location.hash = 'bandregistration';
    } else if (view === 'OrganizerRegistration') {
      setView(view);
      window.location.hash = 'organizerregistration';
    } else if (view === 'Account') {
      setView('Account');
      window.location.hash = 'account';
    } else {
      setView(view);
      if (view !== 'login' && view !== 'logout') window.location.hash = view.toLowerCase();
    }
  };

  const handleLoginClick = async () => {
    if (isLoggedIn) {
      setActiveItem('Account');
      setView('Account');
      window.location.hash = 'account';
    } else {
      setActiveItem('login');
      setView('login');
    }
  };

  return (
    <Navbar bg="white" expand="lg" className="sticky-top">
      <Navbar.Brand>
        <div style={{ textAlign: 'left', paddingLeft: '50px', fontWeight: 'bold' }}>
          My Gig Slots
        </div>
        <div style={{ fontSize: 'smaller', color: 'gray', paddingLeft: '50px' }}>
          Helping musicians find gigs and gigs find musicians
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto" style={{ paddingRight: '350px' }}>
          <Nav.Link onClick={() => handleNavItemClick('home')} active={activeItem === 'home'}>
            Home
          </Nav.Link>
          <Nav.Link onClick={() => handleNavItemClick('list')} active={activeItem === 'list'}>
            Gigs
          </Nav.Link>
          <NavDropdown title="Registration" id="registration-dropdown" active={activeItem === 'Registration'}>
            <NavDropdown.Item onClick={() => handleNavItemClick('BandRegistration')}>
              Band Registration
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNavItemClick('OrganizerRegistration')}>
              Organizer Registration
            </NavDropdown.Item>
          </NavDropdown>
          {isLoggedIn && (
          <Nav.Link onClick={() => handleNavItemClick('Account')} active={activeItem === 'Account'}>
          
           Account
          
          </Nav.Link>
          )}
          {isLoggedIn && (
            <Nav.Link onClick={() => handleNavItemClick('logout')} active={activeItem === 'logout'}>
              Logout
            </Nav.Link>
          )}
        </Nav>
        <Button variant="outline-primary" className="custom-button" onClick={handleLoginClick}>
          {isLoggedIn ? 'Account' : 'Login'}
        </Button>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MainTopBar;


















