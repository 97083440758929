import React from 'react';
import OrganizerRegistration from './OrganizerRegistration';

const MobileOrganizerRegistration = ({ isTesting = true }) => {
  return (
    <div style={styles.container}>
      <OrganizerRegistration isTesting={isTesting} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    padding: '20px',
    backgroundColor: '#fff',
    height: '100vh', // Adjust as needed
  },
};

export default MobileOrganizerRegistration;
