import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const PaymentForm = ({ onCardChange }) => {
  const handleCardChange = (event) => {
    if (onCardChange) {
      onCardChange(event);
    }
  };

  return (
    <div style={{
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '300px',
      padding: '20px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      backgroundColor: '#fff',
    }}>
      <label style={{ marginBottom: '10px' }}>
        Card details
      </label>
      <CardElement
        options={{
          style: {
            base: {
              fontSize: '16px',
              fontFamily: 'Arial, sans-serif',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#fa755a',
            },
          },
          hidePostalCode: true, // Adjust based on your needs
        }}
        onChange={handleCardChange}
      />
    </div>
  );
};

export default PaymentForm;
