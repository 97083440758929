const generateGeolocation = async (address, country, zipcode) => {
    try {
      // Combine address, country, and zipcode into a single string for geocoding
      const fullAddress = `${address}, ${country} ${zipcode}`;
  
      // Perform geocoding using a geocoding API (e.g., Google Maps Geocoding API)
      const geocodingApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(fullAddress)}&key=AIzaSyAF2Q8fA6MwI4ggg-uxaSnOk4tCeCZH7Ds`;
  
      const response = await fetch(geocodingApiUrl);
      const data = await response.json();
  
      if (data.status === 'OK' && data.results.length > 0) {
        const result = data.results[0];
  
        // Extract relevant geolocation information
        const geolocation = {
          geolocated: '1',
          geolocation_city: result.address_components.find(comp => comp.types.includes('locality'))?.long_name || '',
          geolocation_country_long: result.address_components.find(comp => comp.types.includes('country'))?.long_name || '',
          geolocation_country_short: result.address_components.find(comp => comp.types.includes('country'))?.short_name || '',
          geolocation_formatted_address: result.formatted_address || '',
          geolocation_lat: result.geometry.location.lat.toString() || '',
          geolocation_long: result.geometry.location.lng.toString() || '',
          geolocation_postcode: result.address_components.find(comp => comp.types.includes('postal_code'))?.long_name || '',
          geolocation_state_long: result.address_components.find(comp => comp.types.includes('administrative_area_level_1'))?.long_name || '',
          geolocation_state_short: result.address_components.find(comp => comp.types.includes('administrative_area_level_1'))?.short_name || '',
        };
  
        return geolocation;
      } else {
        throw new Error('Geocoding failed');
      }
    } catch (error) {
      console.error('Error generating geolocation:', error.message || 'Unknown error');
      return null;
    }
  };
  
  export default generateGeolocation;
  